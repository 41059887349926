import monoApi from "@/core/services/monoapi.service"

// action types
export const GET_CATEGORIES = "getCategories";

// mutation types
export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES'
export const REQUEST_CATEGORIES_SUCCESS = 'REQUEST_CATEGORIES_SUCCESS'
export const REQUEST_CATEGORIES_ERROR = 'REQUEST_CATEGORIES_ERROR'

export default {
    state: {
        isFetching: false,
        error: null,
        item: {},
        items:[],
        validation: {}
    },
    getters: {
        /**
         * Get list of breadcrumbs for current page
         * @param state
         * @returns {*}
         */
        
    },
    actions: {
        /**
         * Set the breadcrumbs list
         * @param state
         * @param payload
         */
        [GET_CATEGORIES] ({ commit }, payload ) {      
            commit(REQUEST_CATEGORIES)
            return monoApi.get(`/categories/owner/${payload}`)
                .then(res => {
                    commit(REQUEST_CATEGORIES_SUCCESS, {
                        payload: res.data
                    })
                })
                .catch(error => {
                    commit(REQUEST_CATEGORIES_ERROR, { error })
                })
        },  
    },
    mutations: {
        [REQUEST_CATEGORIES] (state) {
            state.isFetching = true
            state.error = null
        },
    
        [REQUEST_CATEGORIES_SUCCESS] (state, action) {
        state.isFetching = false
        state.items = [...action.payload]    
        state.error = null
        },
    
        [REQUEST_CATEGORIES_ERROR] (state, action) {
        state.isFetching = false
        state.error = action.error
        }
    }        
}
