import monoApi from '@/core/services/monoapi.service';

// action types

export const GET_SELLER_PRODUCTS = 'getSellerProducts';
export const GET_SELLER_SIZES = 'getSellerSizes';
export const GET_SELLER_EXTRAS = 'getSellerExtras';
export const GET_SELLER_VARIANTS = 'getSellerVariants';
export const GET_SELLER = 'getSeller';

// mutation types
export const GET_SELLER_PRODUCTS_BEGIN = 'getSellerProductsBegin';
export const SET_SELLER_PRODUCTS = 'setSellerProducts';
export const GET_SELLER_PRODUCTS_END = 'getSellerProductsEnd';

export const GET_SELLER_SIZES_BEGIN = 'getSellerSizesBegin';
export const SET_SELLER_SIZES = 'setSellerSizes';
export const GET_SELLER_SIZES_END = 'getSellerSizesEnd';

export const GET_SELLER_VARIANTS_BEGIN = 'getSellerVariantsBegin';
export const SET_SELLER_VARIANTS = 'setSellerVariants';
export const GET_SELLER_VARIANTS_END = 'getSellerVariantsEnd';

export const GET_SELLER_EXTRAS_BEGIN = 'getSellerVariantsBegin';
export const SET_SELLER_EXTRAS = 'setSellerVariants';
export const GET_SELLER_EXTRAS_END = 'getSellerVariantsEnd';

export const GET_SELLER_BEGIN = 'getSellerBegin';
export const SET_SELLER = 'setSellerVariants';
export const GET_SELLER_END = 'getSellerEnd';

export default {
  state: {
    isFetching: false,
    orders: [],
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [GET_SELLER_PRODUCTS](context, payload) {
      context.commit(GET_SELLER_PRODUCTS_BEGIN);
      return monoApi
        .get(`/owner/${payload}`)
        .then((res) => {
          context.commit(SET_SELLER_PRODUCTS, res.data.orders);
        })
        .catch((err) => {
          context.commit(GET_SELLER_PRODUCTS_END);
        });
    },
    [GET_SELLER](context, payload) {
      context.commit(GET_SELLER_BEGIN);
      return monoApi
        .get(`/sellers/${payload}`)
        .then((res) => {
          context.commit(SET_SELLER, res.data.orders);
        })
        .catch((err) => {
          context.commit(GET_SELLER_END);
        });
    },
    [GET_SELLER_SIZES](context, payload) {
      context.commit(GET_SELLER_SIZES_BEGIN);
      return monoApi
        .get(`/owner/${payload}`)
        .then((res) => {
          context.commit(SET_SELLER_SIZES, res.data.orders);
        })
        .catch((err) => {
          context.commit(GET_SELLER_SIZES_END);
        });
    },
    [GET_SELLER_VARIANTS](context, payload) {
      context.commit(GET_SELLER_VARIANTS_BEGIN);
      return monoApi
        .get(`/owner/${payload}`)
        .then((res) => {
          context.commit(SET_SELLER_VARIANTS, res.data.orders);
        })
        .catch((err) => {
          context.commit(GET_SELLER_VARIANTS_END);
        });
    },
    [GET_SELLER_EXTRAS](context, payload) {
      context.commit(GET_SELLER_EXTRAS_BEGIN);
      return monoApi
        .get(`/extras/`)
        .then((res) => {
          context.commit(SET_SELLER_EXTRAS, res.data.orders);
        })
        .catch((err) => {
          context.commit(GET_SELLER_EXTRAS_END);
        });
    },
    // [STORE_ORDER](context, payload) {
    //   context.commit(ADD_ORDER, payload)
    // },
    // [PROCESS_LOCAL_ORDER](context, {orderId, status}) {
    //   debugger
    //   context.commit(PROCESS_LOCAL_ORDER_BEGIN)
    //   return monoApi.post(`/orders/processLocal/${orderId}`, {status})
    //     .then(res1=>{

    //       //Refrescamos toda la colección de orders para registrar
    //       //posibles variaciones de precio
    //       return monoApi.get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
    //       .then(res2=>{
    //         context.commit(SET_ALL_ORDERS, res2.data.orders)
    //         context.commit(PROCESS_LOCAL_ORDER_SUCCESS)
    //         return 'Pedido procesado con éxito!! 👏👏👏'
    //       })
    //       .catch(err=>{
    //           context.commit(PROCESS_LOCAL_ORDER_ERROR)
    //           return err
    //         }
    //       )

    //     })
    //     .catch(err=>{
    //         context.commit(ERROR_ALL_ORDERS)
    //         return Promise.reject('Error')
    //       }
    //     )
    // },
    // [PROCESS_STUART](context, orderId) {
    //   context.commit(PROCESS_STUART_BEGIN)
    //   return monoApi.post(`/orders/processStuart/${orderId}`)
    //     .then(res1=>{

    //       //Refrescamos toda la colección de orders para registrar
    //       //posibles variaciones de precio
    //       debugger
    //       return monoApi.get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
    //       .then(res2=>{
    //         context.commit(SET_ALL_ORDERS, res2.data.orders)
    //         context.commit(PROCESS_STUART_SUCCESS)
    //         return 'Pedido procesado con éxito!! 👏👏👏'
    //       })
    //       .catch(err=>{
    //           context.commit(PROCESS_STUART_ERROR)
    //           return err
    //         }
    //       )

    //     })
    //     .catch(err=>{
    //         context.commit(ERROR_ALL_ORDERS)
    //         return Promise.reject('Error')
    //       }
    //     )
    // },
    // [PROCESS_RECOGIDA](context, payload) {
    //   context.commit(PROCESS_RECOGIDA_BEGIN)
    //   return monoApi.post(`/orders/processRecogida/${payload}`)
    //     .then(res1=>{
    //       //Refrescamos toda la colección de orders para registrar
    //       //posibles variaciones de precio
    //       return monoApi.get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
    //       .then(res2=>{
    //         context.commit(SET_ALL_ORDERS, res2.data.orders)
    //         context.commit(PROCESS_RECOGIDA_SUCCESS)
    //         return 'Pedido entregado al cliente!! 👏👏👏'
    //       })
    //       .catch(err=>{
    //           context.commit(PROCESS_RECOGIDA_ERROR)
    //           return err
    //         }
    //       )

    //     })
    //     .catch(err=>{
    //         context.commit(ERROR_ALL_ORDERS)
    //         return Promise.reject('Error')
    //       }
    //     )
    // }
  },
  mutations: {
    [SET_SELLER_EXTRAS](state) {
      state.isFetching = false;
    },
    [SET_SELLER](state) {
      state.isFetching = false;
    },
  },
};
