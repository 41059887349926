import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import monoApi from '@/core/services/monoapi.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_USER = 'updateUser';
export const RELOAD_USER = 'reloadUser';
export const UPDATE_SELLER = 'updateSeller';
export const UPDATE_CP = 'updateCp';
export const DELETE_CP = 'deleteCp';
export const ADD_CP = 'addCp';
export const GET_SELLER = 'getSeller';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const SET_SELLER = 'setSeller';
export const SET_CP = 'setCp';
export const BEGIN_UPDATE_SELLER = 'beginUpdateSeller';
export const END_UPDATE_SELLER = 'endUpdateSeller';
export const BEGIN_UPDATE_CP = 'beginUpdateCp';
export const END_UPDATE_CP = 'endUpdateCp';
export const BEGIN_DELETE_CP = 'beginDeleteCp';
export const END_DELETE_CP = 'endDeleteCp';
export const BEGIN_ADD_CP = 'beginAddCp';
export const END_ADD_CP = 'endAddCp';
export const BEGIN_GET_SELLER = 'beginGetSeller';
export const END_GET_SELLER = 'endGetSeller';

const state = {
  errors: null,
  loading: false,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getZipCodes(state) {
    return state.user.seller.deliveryCodes;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return monoApi
      .post('/backOfficeUsers/login', credentials)
      .then((res) => {
        const user = res.data;
        localStorage.setItem('userToken', user.token);
        localStorage.setItem('userId', user._id);
        context.commit(SET_AUTH, { ...user });
        return Promise.resolve(`Bienvenido al sistema, ${user.firstName}!!`);
      })
      .catch((err) => {
        return Promise.reject(err.response.data.errors.message);
      });
    // return new Promise(resolve => {
    //   ApiService.post("login", credentials)
    //     .then(({ data }) => {
    //       context.commit(SET_AUTH, data);
    //       resolve(data);
    //     })
    //     .catch(({ response }) => {
    //       context.commit(SET_ERROR, response.data.errors);
    //     });
    // });
  },
  [RELOAD_USER](context, credentials) {
    return monoApi
      .post('/backOfficeUsers/login', credentials)
      .then((res) => {
        const user = res.data;
        localStorage.setItem('userToken', user.token);
        localStorage.setItem('userId', user._id);
        context.commit(SET_AUTH, { ...user });
        return Promise.resolve(`Bienvenido al sistema, ${user.firstName}!!`);
      })
      .catch((err) => {
        return Promise.reject(err.response.data.errors.message);
      });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users', { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get('verify')
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
  [UPDATE_SELLER](context, payload) {
    context.commit(BEGIN_UPDATE_SELLER);
    return monoApi
      .patch(`/sellers/${payload._id}`, { ...payload })
      .then((data) => {
        context.commit(SET_SELLER, data);
        context.commit(END_UPDATE_SELLER);
        return Promise.resolve(data);
      });
  },
  [UPDATE_CP](context, payload) {
    context.commit(BEGIN_UPDATE_CP);
    let { deliveryCodes } = context.state.user.seller;
    let newDeliveryCodes = deliveryCodes.map((element) => {
      if (element.code === payload.code) {
        return {
          ...payload,
        };
      }
      return element;
    });
    context.commit(SET_CP, newDeliveryCodes);
    context.commit(END_UPDATE_CP);
    return Promise.resolve(
      context.dispatch(UPDATE_SELLER, context.state.user.seller)
    );
  },
  [DELETE_CP](context, payload) {
    context.commit(BEGIN_DELETE_CP);
    console.log(payload);
    let { deliveryCodes } = context.state.user.seller;
    let newDeliveryCodes = deliveryCodes.filter(
      (element) => element.code !== payload
    );
    console.log(newDeliveryCodes);
    context.commit(SET_CP, newDeliveryCodes);
    context.commit(END_DELETE_CP);
    return Promise.resolve(
      context.dispatch(UPDATE_SELLER, context.state.user.seller)
    );
  },
  [ADD_CP](context, payload) {
    context.commit(BEGIN_ADD_CP);
    let newDeliveryCodes = context.state.user.seller.deliveryCodes;
    newDeliveryCodes.push(payload);
    context.commit(SET_CP, newDeliveryCodes);
    context.commit(END_ADD_CP);
    return Promise.resolve(
      context.dispatch(UPDATE_SELLER, context.state.user.seller)
    );
  },
  [GET_SELLER](context, payload) {
    context.commit(BEGIN_GET_SELLER);
    return monoApi.get(`/sellers/current-seller`).then((res) => {
      context.commit(SET_SELLER, {
        data: {
          data: {
            seller: res,
          },
        },
      });
      context.commit(END_GET_SELLER);
      return Promise.resolve(data);
    });
    context.commit(SET_SELLER, newDeliveryCodes);
    context.commit(END_GET_SELLER);
  },
};

const mutations = {
  [BEGIN_UPDATE_SELLER](state, error) {
    state.loading = true;
  },
  [END_UPDATE_SELLER](state, error) {
    state.loading = false;
  },
  [BEGIN_UPDATE_CP](state, error) {
    state.loading = true;
  },
  [END_UPDATE_CP](state, error) {
    state.loading = false;
  },
  [BEGIN_DELETE_CP](state, error) {
    state.loading = true;
  },
  [END_DELETE_CP](state, error) {
    state.loading = false;
  },
  [END_GET_SELLER](state, error) {
    state.loading = false;
  },
  [BEGIN_ADD_CP](state, error) {
    state.loading = true;
  },
  [BEGIN_GET_SELLER](state, error) {
    state.loading = true;
  },
  [END_ADD_CP](state, error) {
    state.loading = false;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_SELLER](state, data) {
    state.user.seller = { ...data.data.seller };
  },
  [SET_CP](state, data) {
    state.user.seller.deliveryCodes = data;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    //TODO pasa dos veces por aquí, la segunda vacía. Tengo que investigar por qué
    // de momento, lo cuadramos así
    if (user) {
      state.user = user;
    }
    state.errors = { ...user };
    JwtService.saveToken(state.user.token);
    state.loading = false;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
