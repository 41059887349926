import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './core/services/store';
import ApiService from './core/services/api.service';
import MockService from './core/mock/mock.service';
import { VERIFY_AUTH } from './core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import filters from '@/filters';
import VuetifyConfirm from 'vuetify-confirm';
import VueLoadingOverlay from 'vue-loading-overlay';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from './core/plugins/vue-i18n';
import vuetify from './core/plugins/vuetify';
import './core/plugins/portal-vue';
import './core/plugins/bootstrap-vue';
import './core/plugins/perfect-scrollbar';
import './core/plugins/highlight-js';
import './core/plugins/inline-svg';
import './core/plugins/apexcharts';
import './core/plugins/metronic';
import Vuelidate from 'vuelidate';
import AppSocket from './core/plugins/socket';
import '@mdi/font/css/materialdesignicons.css';

// API service init
ApiService.init();

// Remove this to disable mock API
MockService.init();

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

filters();

try {
  Vue.use(AppSocket, { connection: process.env.VUE_APP_API_URL });
  //Vue.use(AppSocket, {connection: 'http://192.168.0.156:3001'})  //34.248.181.248
  //Vue.use(AppSocket, {connection: 'http://34.248.181.248:3001'})  //34.248.181.248
  //Vue.use(AppSocket, {connection: 'http://127.0.0.1:3001'})  //34.248.181.248
  Vue.use(VuetifyConfirm, { vuetify });
  Vue.use(VueLoadingOverlay);
  Vue.use(Vuelidate);

  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
} catch (error) {
  console.log(error);
}
