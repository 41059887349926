import monoApi from "@/core/services/monoapi.service"

// action types
export const GET_VARIANTS = "getVariants";

// mutation types
export const REQUEST_VARIANTS = 'REQUEST_VARIANTS'
export const REQUEST_VARIANTS_SUCCESS = 'REQUEST_VARIANTS_SUCCESS'
export const REQUEST_VARIANTS_ERROR = 'REQUEST_VARIANTS_ERROR'

export default {
    state: {
        isFetching: false,
        error: null,
        item: {},
        items:[],
        validation: {}
    },
    getters: {
        /**
         * Get list of breadcrumbs for current page
         * @param state
         * @returns {*}
         */
        
    },
    actions: {
        /**
         * Set the breadcrumbs list
         * @param state
         * @param payload
         */
        [GET_VARIANTS] ({ commit } ) {      
            commit(REQUEST_VARIANTS)
            return monoApi.get(`/variants/`)
                .then(res => {
                    commit(REQUEST_VARIANTS_SUCCESS, {
                        payload: res.data
                    })
                })
                .catch(error => {
                    commit(REQUEST_VARIANTS_ERROR, { error })
                })
        },  
    },
    mutations: {
        [REQUEST_VARIANTS] (state) {
            state.isFetching = true
            state.error = null
        },
    
        [REQUEST_VARIANTS_SUCCESS] (state, action) {
        state.isFetching = false
        state.items = [...action.payload]    
        state.error = null
        },
    
        [REQUEST_VARIANTS_ERROR] (state, action) {
        state.isFetching = false
        state.error = action.error
        }
    }        
}
