import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import sellers from "./sellers.module";
import extras from "./extras.module";
import variants from "./variants.module";
import allergens from "./allergens.module";
import categories from "./categories.module";
import sizes from "./sizes.module";
import orders from "./orders.module";
import products from "./products.module";
import deals from "./deals.module";
import logs from "./logs.module";
import reports from "./reports.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    sellers,
    orders,
    extras,
    variants,
    categories,
    sizes,
    allergens,
    deals,
    products,
    logs,
    reports,
  },
  plugins: [
    createPersistedState({
      paths: ["auth"],
    }),
  ],
});
