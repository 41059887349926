import monoApi from '@/core/services/monoapi.service';
import router from '@/router/index';

// action types
export const GET_DEALS_BY_OWNER_ID = 'getDeals';
export const GET_DEAL = 'getDeal';
export const UPDATE_DEAL = 'updateDeal';
export const CREATE_DEAL = 'createDeal';

// mutation types
export const REQUEST_DEALS = 'REQUEST_DEALS';
export const REQUEST_DEALS_SUCCESS = 'REQUEST_DEALS_SUCCESS';
export const REQUEST_DEALS_ERROR = 'REQUEST_DEALS_ERROR';
export const REQUEST_DEAL = 'REQUEST_DEAL';
export const REQUEST_DEAL_SUCCESS = 'REQUEST_DEAL_SUCCESS';
export const REQUEST_DEAL_ERROR = 'REQUEST_DEAL_ERROR';
export const UPDATE_DEAL_SUCCESS = 'UPDATE_DEAL_SUCCESS';
export const UPDATE_DEAL_ERROR = 'UPDATE_DEAL_ERROR';
export const CREATE_DEAL_SUCCESS = 'CREATE_DEAL_SUCCESS';
export const CREATE_DEAL_ERROR = 'CREATE_DEAL_ERROR';

export default {
  state: {
    isFetching: false,
    error: null,
    item: {},
    items: [],
    validation: {},
  },
  getters: {},
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [GET_DEALS_BY_OWNER_ID]({ commit }, id) {
      commit(REQUEST_DEALS);
      return monoApi
        .get(`/deals/owner/${id}`)
        .then((res) => {
          commit(REQUEST_DEALS_SUCCESS, {
            payload: res.data,
          });
        })
        .catch((error) => {
          commit(REQUEST_DEALS_ERROR, { error });
        });
    },
    [GET_DEAL]({ commit }, id) {
      return monoApi
        .get(`/deals/${id}`)
        .then((res) => {
          commit(REQUEST_DEAL_SUCCESS, {
            payload: res.data,
          });
        })
        .catch((error) => {
          commit(REQUEST_DEAL_ERROR, { error });
        });
    },
    [UPDATE_DEAL]({ commit, state }, deal) {
      return monoApi
        .patch(`/deals/${deal._id}`, deal)
        .then((res) => {
          const newItems = [];
          state.items.forEach((item) => {
            if (item._id === res.data.deal._id) newItems.push(res.data.deal);
            else newItems.push(item);
          });
          commit(UPDATE_DEAL_SUCCESS, {
            payload: deal,
          });
          router.push(`/ofertas?status=success`);
        })
        .catch((error) => {
          commit(UPDATE_DEAL_ERROR, { error });
        });
    },
    [CREATE_DEAL]({ commit }, payload) {
      return monoApi
        .post(`/deals`, payload)
        .then((res) => {
          commit(CREATE_DEAL_SUCCESS, {
            payload: res.data.deal,
          });
          router.push(`/ofertas?status=success`);
        })
        .catch((error) => {
          commit(CREATE_DEAL_ERROR, { error });
        });
    },
  },
  mutations: {
    [REQUEST_DEALS](state) {
      state.isFetching = true;
      state.error = null;
    },

    [REQUEST_DEALS_SUCCESS](state, action) {
      state.isFetching = false;
      state.items = [...action.payload];
      state.error = null;
    },

    [REQUEST_DEALS_ERROR](state, action) {
      state.isFetching = false;
      state.error = action.error;
    },
    [REQUEST_DEAL_SUCCESS](state, action) {
      state.isFetching = false;
      state.item = action.payload;
      state.error = null;
    },
    [CREATE_DEAL_SUCCESS](state, action) {
      state.isFetching = false;
      state.items = [...state.items, action.payload];
      state.error = null;
    },
    [UPDATE_DEAL_SUCCESS](state, action) {
      state.items = [...state.items, action.payload];
      state.isFetching = false;
      state.error = null;
    },
    [UPDATE_DEAL_ERROR](state, action) {
      state.isFetching = false;
      state.error = action.error;
    },
    [CREATE_DEAL_ERROR](state, action) {
      state.isFetching = false;
      state.error = action.error;
    },

    [REQUEST_DEAL_ERROR](state, action) {
      state.isFetching = false;
      state.error = action.error;
    },
  },
};
