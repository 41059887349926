import axios from 'axios';
import router from '@/router/index';

const BASE_URL = process.env.VUE_APP_API_URL + '/api/v1/';

var instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('userToken') || '';

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // eslint-disable-next-line no-debugger
    const originalRequest = error.config;

    // if (error.response.status === 401 && !originalRequest._retry) {
    if (error.response.status === 401) {
      // const accessToken = localStorage.getItem('accessToken');
      localStorage.removeItem('userToken');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

export default instance;
