import monoApi from '@/core/services/monoapi.service';

// action types
export const GET_ORDERS = 'getOrders';
export const REFRESH_ORDERS = 'refreshOrders';
export const STORE_ORDER = 'storeOrder';
export const PROCESS_ORDER = 'processOrder';
export const PROCESS_LOCAL_ORDER = 'processLocalOrder';
export const PROCESS_RECOGIDA = 'processRecogida';
export const PROCESS_STUART = 'processStuart';
export const PROCESS_VROMO = 'processVromo';
export const CANCEL_STUART = 'cancelStuart';
export const REFUND_ORDER = 'refundOrder';
export const SELECT_ORDER = 'selectOrder';

// mutation types
export const ADD_ORDER = 'addOrder';
export const FETCH_ALL_ORDERS = 'fetchAllOrders';
export const SET_ALL_ORDERS = 'setAllOrders';
export const ERROR_ALL_ORDERS = 'errorAllOrders';
export const PROCESS_ORDER_BEGIN = 'processOrderBegin';
export const PROCESS_ORDER_SUCCESS = 'processOrderSuccess';
export const PROCESS_LOCAL_ORDER_BEGIN = 'processLocalOrderBegin';
export const PROCESS_LOCAL_ORDER_SUCCESS = 'processLocalOrderSuccess';
export const STATE_LOADING_FALSE = 'stateLoadingFalse';
export const PROCESS_LOCAL_ORDER_ERROR = 'processLocalOrderError';
export const PROCESS_RECOGIDA_BEGIN = 'processRecogidaBegin';
export const PROCESS_RECOGIDA_SUCCESS = 'processRecogidaSuccess';
export const PROCESS_RECOGIDA_ERROR = 'processRecogidaError';
export const PROCESS_REFUND_BEGIN = 'processRefundBegin';
export const PROCESS_REFUND_SUCCESS = 'processRefundSuccess';
export const PROCESS_REFUND_ERROR = 'processRefundError';
export const PROCESS_STUART_BEGIN = 'processStuartBegin';
export const PROCESS_STUART_SUCCESS = 'processStuartSuccess';
export const PROCESS_STUART_ERROR = 'processStuartError';
export const CANCEL_STUART_BEGIN = 'cancelStuartBegin';
export const CANCEL_STUART_SUCCESS = 'cancelStuartSuccess';
export const CANCEL_STUART_ERROR = 'cancelStuartError';
export const MANUAL_FINISH = 'manualFinish';
export const MANUAL_FINISH_BEGIN = 'manualFinishBegin';
export const MANUAL_FINISH_SUCCESS = 'manualFinishSuccess';
export const MANUAL_FINISH_ERROR = 'manualFinishError';
export const PROCESS_VROMO_BEGIN = 'processVromoBegin';
export const PROCESS_VROMO_SUCCESS = 'processVromouccess';
export const PROCESS_VROMO_ERROR = 'processVromoError';
export const CANCEL_VROMO_BEGIN = 'cancelVromoBegin';
export const CANCEL_VROMO_SUCCESS = 'cancelVromoSuccess';
export const CANCEL_VROMO_ERROR = 'cancelVROMOError';
export const SET_SELECTED_ORDER = 'setSelectedOrder';
export const DEL_SELECTED_ORDER = 'delSelectedOrder';

export default {
  state: {
    isFetching: false,
    selectedOrder: null,
    orders: [],
  },
  getters: {
    getActiveOrders(state) {
      let filtered = state.orders.filter((order) => {
        return (
          order.status === 'Pagado' ||
          order.status === 'En preparación' ||
          order.status === 'En reparto'
        );
      });
      return filtered;
    },

    getProcessedOrders(state) {
      let orders = state.orders.filter((order) => {
        return (
          order.status === 'Entregado' ||
          order.status === 'Cancelado' ||
          order.status === 'Finalizado' ||
          order.status === 'Devuelto'
        );
      });
      return orders;
    },

    getActive(state) {
      let filtered = state.orders.filter((order) => {
        return (
          order.status === 'Pagado' ||
          order.status === 'En espera' ||
          order.status === 'En preparación' ||
          order.status === 'En reparto'
        );
      });
      return filtered;
    },

    getProcessed(state) {
      let filtered = state.orders.filter((order) => {
        return (
          order.status === 'Entregado' ||
          order.status === 'Cancelado' ||
          order.status === 'Finalizado' ||
          order.status === 'Devuelto'
        );
      });
      return filtered;
    },
  },
  actions: {
    [GET_ORDERS](context, payload) {
      context.commit(FETCH_ALL_ORDERS);
      return monoApi
        .get(`/orders/owner/${payload}`)
        .then((res) => {
          if (
            JSON.stringify(context.state.orders) !==
            JSON.stringify(res.data.orders)
          ) {
            context.commit(SET_ALL_ORDERS, res.data.orders);
          } else {
            context.commit(STATE_LOADING_FALSE, res.data.orders);
          }
        })
        .catch((err) => {
          context.commit(ERROR_ALL_ORDERS);
        });
    },
    [STORE_ORDER](context, payload) {
      context.commit(ADD_ORDER, payload);
    },
    [PROCESS_LOCAL_ORDER](context, { orderId, status }) {
      context.commit(PROCESS_LOCAL_ORDER_BEGIN);
      return monoApi
        .post(`/orders/processLocal/${orderId}`, { status })
        .then((res1) => {
          //Refrescamos toda la colección de orders para registrar
          //posibles variaciones de precio
          return monoApi
            .get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
            .then((res2) => {
              context.commit(SET_ALL_ORDERS, res2.data.orders);
              context.commit(PROCESS_LOCAL_ORDER_SUCCESS);
              return 'Pedido procesado con éxito!!  👏 👏 👏';
            })
            .catch((err) => {
              context.commit(PROCESS_LOCAL_ORDER_ERROR);
              return err;
            });
        })
        .catch((err) => {
          context.commit(ERROR_ALL_ORDERS);
          return Promise.reject('Error');
        });
    },
    [MANUAL_FINISH](context, { orderId }) {
      context.commit(MANUAL_FINISH_BEGIN);
      return monoApi
        .post(`/orders/manualFinish/${orderId}`, { status })
        .then((res1) => {
          //Refrescamos toda la colección de orders para registrar
          //posibles variaciones de precio
          return monoApi
            .get(`/orders/owner/${context.rootState.auth.user.seller._id}`)
            .then((res2) => {
              context.commit(SET_ALL_ORDERS, res2.data.orders);
              context.commit(MANUAL_FINISH_SUCCESS);
              return 'Pedido finalizado con éxito!!  👏 👏 👏';
            })
            .catch((err) => {
              context.commit(CANCEL_STUART_ERROR);
              return err;
            });
        })
        .catch((err) => {
          context.commit(MANUAL_FINISH_ERROR);
          return Promise.reject('Error');
        });
    },
    [PROCESS_STUART](context, orderId) {
      context.commit(PROCESS_STUART_BEGIN);
      return monoApi
        .post(`/orders/processStuart/${orderId}`)
        .then((res1) => {
          //Refrescamos toda la colección de orders para registrar
          //posibles variaciones de precio
          return monoApi
            .get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
            .then((res2) => {
              context.commit(SET_ALL_ORDERS, res2.data.orders);
              context.commit(PROCESS_STUART_SUCCESS);
              return 'Pedido procesado con éxito!!  👏 👏 👏';
            })
            .catch((err) => {
              context.commit(PROCESS_STUART_ERROR);
              return err;
            });
        })
        .catch((err) => {
          console.log(err);
          context.commit(ERROR_ALL_ORDERS);
          return Promise.reject(`Stuart error: ${err.response.data.error}`);
        });
    },
    [CANCEL_STUART](context, { jobId, orderId }) {
      context.commit(CANCEL_STUART_BEGIN);
      return monoApi
        .post(`/orders/cancelJob/${jobId}/${orderId}`)
        .then((res1) => {
          if (res1.status === 404) {
            context.commit(CANCEL_STUART_SUCCESS);
            return 'Ha ocurrido un error';
          }
          //Refrescamos toda la colección de orders para registrar
          //posibles variaciones de precio
          return monoApi
            .get(`/orders/owner/${context.rootState.auth.user.seller._id}`)
            .then((res2) => {
              context.commit(SET_ALL_ORDERS, res2.data.orders);
              context.commit(CANCEL_STUART_SUCCESS);
              return 'Reparto cancelado con éxito!!  👏 👏 👏 ';
            })
            .catch((err) => {
              context.commit(CANCEL_STUART_ERROR);
              return err;
            });
        })
        .catch((err) => {
          context.commit(ERROR_ALL_ORDERS);
          return Promise.reject('Error');
        });
    },
    [PROCESS_VROMO](context, orderId) {
      context.commit(PROCESS_VROMO_BEGIN);
      return monoApi
        .post(`/orders/processVromo/${orderId}`)
        .then((res1) => {
          //Refrescamos toda la colección de orders para registrar
          //posibles variaciones de precio
          return monoApi
            .get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
            .then((res2) => {
              context.commit(SET_ALL_ORDERS, res2.data.orders);
              context.commit(PROCESS_VROMO_SUCCESS);
              return 'Pedido procesado con éxito!!  👏 👏 👏';
            })
            .catch((err) => {
              context.commit(PROCESS_VROMO_ERROR);
              return err;
            });
        })
        .catch((err) => {
          console.log(err);
          context.commit(ERROR_ALL_ORDERS);
          return Promise.reject(`Stuart error: ${err.response.data.error}`);
        });
    },
    [REFUND_ORDER](context, orderId) {
      context.commit(PROCESS_REFUND_BEGIN);
      return monoApi
        .post(`/orders/refund/${orderId}`)
        .then((res1) => {
          if (res1.status === 404) {
            context.commit(PROCESS_REFUND_ERROR);
            return 'Ha ocurrido un error';
          }
          //Refrescamos toda la colección de orders para registrar
          //posibles variaciones de precio
          return monoApi
            .get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
            .then((res2) => {
              context.commit(SET_ALL_ORDERS, res2.data.orders);
              context.commit(PROCESS_REFUND_SUCCESS);
              return 'Devolución procesada con éxito!!  👏 👏 👏 ';
            })
            .catch((err) => {
              context.commit(PROCESS_REFUND_ERROR);
              return err;
            });
        })
        .catch((err) => {
          context.commit(ERROR_ALL_ORDERS);
          return Promise.reject('Error');
        });
    },
    [PROCESS_RECOGIDA](context, payload) {
      context.commit(PROCESS_RECOGIDA_BEGIN);
      return monoApi
        .post(`/orders/processRecogida/${payload}`)
        .then((res1) => {
          //Refrescamos toda la colección de orders para registrar
          //posibles variaciones de precio
          return monoApi
            .get(`/orders/owner/${res1.data.updatedOrder.seller._id}`)
            .then((res2) => {
              context.commit(SET_ALL_ORDERS, res2.data.orders);
              context.commit(PROCESS_RECOGIDA_SUCCESS);
              return 'Pedido entregado al cliente!!  👏 👏 👏 ';
            })
            .catch((err) => {
              context.commit(PROCESS_RECOGIDA_ERROR);
              return err;
            });
        })
        .catch((err) => {
          context.commit(ERROR_ALL_ORDERS);
          return Promise.reject('Error');
        });
    },
    [SELECT_ORDER]({ state, commit }, payload) {
      state.orders.forEach((order) => {
        if (order._id === payload) {
          commit(SET_SELECTED_ORDER, order);
        }
      });
    },
  },
  mutations: {
    [FETCH_ALL_ORDERS](state) {
      state.isFetching = true;
    },
    [PROCESS_LOCAL_ORDER_BEGIN](state) {
      state.isFetching = true;
    },
    [PROCESS_RECOGIDA_BEGIN](state) {
      state.isFetching = true;
    },
    [PROCESS_REFUND_BEGIN](state) {
      state.isFetching = true;
    },
    [PROCESS_STUART_BEGIN](state) {
      state.isFetching = true;
    },
    [CANCEL_STUART_BEGIN](state) {
      state.isFetching = true;
    },
    [PROCESS_VROMO_BEGIN](state) {
      state.isFetching = true;
    },
    [CANCEL_VROMO_BEGIN](state) {
      state.isFetching = true;
    },
    [SET_ALL_ORDERS](state, payload) {
      state.orders = [...payload];
      state.isFetching = false;
    },
    [ERROR_ALL_ORDERS](state, payload) {
      state.isFetching = false;
    },
    [STATE_LOADING_FALSE](state, payload) {
      state.isFetching = false;
    },
    [PROCESS_LOCAL_ORDER_ERROR](state) {
      state.isFetching = false;
    },
    [PROCESS_LOCAL_ORDER_SUCCESS](state) {
      state.isFetching = false;
    },
    [PROCESS_RECOGIDA_SUCCESS](state) {
      state.isFetching = false;
    },
    [PROCESS_REFUND_SUCCESS](state) {
      state.isFetching = false;
    },
    [PROCESS_STUART_SUCCESS](state) {
      state.isFetching = false;
    },
    [CANCEL_STUART_SUCCESS](state) {
      state.isFetching = false;
    },
    [PROCESS_VROMO_SUCCESS](state) {
      state.isFetching = false;
    },
    [CANCEL_VROMO_SUCCESS](state) {
      state.isFetching = false;
    },
    [ADD_ORDER](state, payload) {
      state.orders.orders.push(payload);
    },
    [SET_SELECTED_ORDER](state, payload) {
      state.selectedOrder = { ...payload };
    },
  },
};
