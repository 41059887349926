import monoApi from "@/core/services/monoapi.service"

// action types
export const FETCH_SIMPLE_REPORT = "fetchSimpleReport";
export const FETCH_DETAILED_REPORT = "fetchDetailedReport";


// mutation types
export const SET_FETCH = "setFetch";
export const FETCH_REPORT_DATA = "fetchReportData";
export const FETCH_REPORT_DATA_ERROR = "fetchReportDataError";
export const SET_REPORT_DATA = "setReportData";


export default {
  state: {
    isFetching:false,    
    report: []
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [FETCH_DETAILED_REPORT](context, payload) {                
        context.commit(FETCH_REPORT_DATA)       
        return monoApi.get(`/reports/detailed/owner/${payload.id}/${payload.f_inicio}/${payload.f_fin}`)
        .then(res=>{
          context.commit(SET_REPORT_DATA, res.data.report)          
        })
        .catch(err=>{          
          console.log(err)
            context.commit(FETCH_REPORT_DATA_ERROR)            
          }            
        )        
    },
  },
  mutations: {
    [FETCH_REPORT_DATA](state) {
      state.isFetching=true
    },    
    [FETCH_REPORT_DATA_ERROR](state) {
        state.isFetching=false
    },    
    [SET_REPORT_DATA](state, report) {
      state.isFetching=false
      state.report = {...report}      
    },    
  }
};
