import monoApi from "@/core/services/monoapi.service"

// action types
export const GET_SELLER_LOGS = "getSellerLogs";
export const GET_PRODUCTS_BY_OWNER_ID = "getProductsByOwnerId";
export const STORE_ORDER = "storeOrder";

// mutation types
export const REQUEST_SELLER_LOGS_BEGIN = 'REQUEST_SELLER_LOGS_BEGIN'
export const REQUEST_SELLER_LOGS_SUCCESS = 'REQUEST_SELLER_LOGS_SUCCESS'
export const REQUEST_SELLER_LOGS_ERROR = 'REQUEST_SELLER_LOGS_ERROR'


export default {
    state: {
        isFetching: false,
        error: null,
        item: {},
        items:[],
        validation: {}  
    },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
        [GET_SELLER_LOGS] ({ commit },  id ) {      
            commit(REQUEST_SELLER_LOGS_BEGIN)
            return monoApi.get(`/logs/seller/${id}`)
                .then(res => {
                    commit(REQUEST_SELLER_LOGS_SUCCESS, {
                        payload: res.data
                    })
                })
                .catch(error => {
                    commit(REQUEST_SELLER_LOGS_ERROR, { error })
                })
        },

  },
  mutations: {
    [REQUEST_SELLER_LOGS_BEGIN] (state) {
        state.isFetching = true
        state.error = null
    },

    [REQUEST_SELLER_LOGS_SUCCESS] (state, action) {
        state.isFetching = false
        state.items = [...action.payload]    
        state.error = null
    },

    [REQUEST_SELLER_LOGS_ERROR] (state, action) {
        state.isFetching = false
        state.error = action.error
    }       
  }
};
