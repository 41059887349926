import Vue from 'vue';
import moment from 'moment';

const filters = () => {
  Vue.filter('capitalize', function(value) {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }

    return '';
  });

  Vue.filter('isToday', function(value, formatType = 'LL') {
    if (!value) return '';
    let dateFormated = moment(value);
    let today = moment();
    dateFormated.locale('es');
    return dateFormated.format(formatType) === today.format(formatType);
  });

  Vue.filter('formatDate', function(value, formatType = 'LL') {
    if (!value) return '';
    let dateFormated = moment(value);
    dateFormated.locale('es');
    return dateFormated.format(formatType);
  });

  Vue.filter('formatDateShort', function(value, formatType = 'DD-MM-YYYY') {
    if (!value) return '';
    let dateFormated = moment(value);
    dateFormated.locale('es');
    return dateFormated.format(formatType);
  });

  Vue.filter('formatTime', function(value, formatType = 'HH:mm') {
    if (!value) return '';
    return moment(value).format(formatType);
  });

  Vue.filter('fromNow', function(value) {
    if (!value) return '';

    return moment(value).fromNow();
  });

  Vue.filter('betweenTime', function(start, end) {
    var ahora = new Date().getHours();
    return ahora >= parseInt(start) && ahora <= parseInt(end);
  }),
    Vue.filter('formatPrice', function(data) {
      if (data.description) {
        return data.price > 0
          ? data.name + '(' + data.description + ') +' + data.price + '€'
          : data.name;
      }
      return data.price > 0 ? data.name + ' +' + data.price + '€' : data.name;
    });

  Vue.filter('formatStatus', function(status) {
    switch (status) {
      case 'Pagado':
        return 'PAGADO';
      case 'En preparación':
        return 'EN PREPARACIÓN';
      case 'En reparto':
        return 'EN REPARTO';
      case 'Entregado':
        return 'ENTREGADO';
      default:
      // code block
    }
  });

  Vue.filter('formatStatusColor', function(status) {
    switch (status) {
      case 'Pagado':
        return '#9663e0';
      case 'En preparación':
        return '#edc143';
      case 'En reparto':
        return '#e97a43';
      case 'Entregado':
        return '#35cd76';
      default:
      // code block
    }
  });

  Vue.filter('formatPrice', function(price) {
    return Math.round(price * 100) / 100;
  });

  Vue.filter('isLocalAvailable', function(currentCode) {
    let codigos = this.getZipCodes;
    let codes = codigos.filter((code) => {
      return code.code === currentCode && code.local;
    });
    return codes.length > 0;
  });

  Vue.filter('capitalize', function(str) {
    if (typeof str === 'string') {
      return str.replace(/^\w/, (c) => c.toUpperCase());
    } else {
      return '';
    }
  });

  Vue.filter('closeTakeAway', function(str = '') {
    let now = new Date();
    debugger;
    let recogida = new Date(str);
    if (
      (recogida.getTime() - now.getTime()) / 60000 - 60 < 20 &&
      (recogida.getTime() - now.getTime()) / 60000 - 60 > 0
    ) {
      return true;
    }
    return false;
  });
};

export default filters;
