import monoApi from "@/core/services/monoapi.service"

// action types
export const GET_ALLERGENS = "getAllergens";

// mutation types
export const REQUEST_ALLERGENS = 'REQUEST_ALLERGENS'
export const REQUEST_ALLERGENS_SUCCESS = 'REQUEST_ALLERGENS_SUCCESS'
export const REQUEST_ALLERGENS_ERROR = 'REQUEST_ALLERGENS_ERROR'

export default {
    state: {
        isFetching: false,
        error: null,
        item: {},
        items:[],
        validation: {}
    },
    getters: {
        /**
         * Get list of breadcrumbs for current page
         * @param state
         * @returns {*}
         */
        
    },
    actions: {
        /**
         * Set the breadcrumbs list
         * @param state
         * @param payload
         */
        [GET_ALLERGENS] ({ commit }, payload ) {      
            commit(REQUEST_ALLERGENS)
            return monoApi.get(`/allergens`)
                .then(res => {
                    commit(REQUEST_ALLERGENS_SUCCESS, {
                        payload: res.data
                    })
                })
                .catch(error => {
                    commit(REQUEST_ALLERGENS_ERROR, { error })
                })
        },  
    },
    mutations: {
        [REQUEST_ALLERGENS] (state) {
            state.isFetching = true
            state.error = null
        },
    
        [REQUEST_ALLERGENS_SUCCESS] (state, action) {
        state.isFetching = false
        state.items = [...action.payload]    
        state.error = null
        },
    
        [REQUEST_ALLERGENS_ERROR] (state, action) {
        state.isFetching = false
        state.error = action.error
        }
    }        
}
