import router from '@/router/index';
import monoApi from '@/core/services/monoapi.service';

// action types
export const GET_PRODUCT = 'getProduct';
export const CREATE_PRODUCT = 'createProduct';
export const UPDATE_PRODUCT = 'updateProduct';
export const DELETE_PRODUCT = 'deleteProduct';
export const GET_PRODUCTS_BY_OWNER_ID = 'getProductsByOwnerId';
export const STORE_ORDER = 'storeOrder';

// mutation types
export const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
export const REQUEST_PRODUCT_SUCCESS = 'REQUEST_PRODUCT_SUCCESS';
export const REQUEST_PRODUCT_ERROR = 'REQUEST_PRODUCT_ERROR';
export const REQUEST_CREATE_PRODUCT = 'REQUEST_CREATE_PRODUCT';
export const REQUEST_CREATE_PRODUCT_SUCCESS = 'REQUEST_CREATE_PRODUCT_SUCCESS';
export const REQUEST_CREATE_PRODUCT_ERROR = 'REQUEST_CREATE_PRODUCT_ERROR';
export const REQUEST_DELETE_PRODUCT = 'REQUEST_DELETE_PRODUCT';
export const REQUEST_DELETE_PRODUCT_SUCCESS = 'REQUEST_DELETE_PRODUCT_SUCCESS';
export const REQUEST_DELETE_PRODUCT_ERROR = 'REQUEST_DELETE_PRODUCT_ERROR';
export const REQUEST_UPDATE_PRODUCT = 'REQUEST_UPDATE_PRODUCT';
export const REQUEST_UPDATE_PRODUCT_SUCCESS = 'REQUEST_UPDATE_PRODUCT_SUCCESS';
export const REQUEST_UPDATE_PRODUCT_ERROR = 'REQUEST_UPDATE_PRODUCT_ERROR';
export const REQUEST_PRODUCT_BY_OWNER = 'REQUEST_PRODUCT_BY_OWNER';
export const REQUEST_PRODUCT_BY_OWNER_SUCCESS =
  'REQUEST_PRODUCT_BY_OWNER_SUCCESS';
export const REQUEST_PRODUCT_BY_OWNER_ERROR = 'REQUEST_PRODUCT_BY_OWNER_ERROR';
export const REQUEST_ALL_PRODUCTS = 'REQUEST_ALL_PRODUCTS';
export const REQUEST_ALL_PRODUCTS_SUCCESS = 'REQUEST_ALL_PRODUCTS_SUCCESS';
export const REQUEST_ALL_PRODUCTS_ERROR = 'REQUEST_ALL_PRODUCTS_ERROR';
export const REQUEST_RESTAURANT_SUCCESS = 'REQUEST_RESTAURANT_SUCCESS';
export const REQUEST_RESTAURANT_ERROR = 'REQUEST_PRODUCT_ERROR';

export default {
  state: {
    isFetching: false,
    error: null,
    item: {},
    items: [],
    validation: {},
  },
  getters: {
    getProductsByCategory: (state) => (id) => {
      let products = state.items.filter((product) => {
        return product.category === id;
      });
      return products;
    },
    getProduct: (state) => (id) => {
      return state.items.find((product) => product._id === id);
    },
  },
  actions: {
    [GET_PRODUCT]({ commit }, id) {
      return monoApi
        .get(`/products/${id}`)
        .then((res) => {
          commit(REQUEST_PRODUCT_SUCCESS, {
            payload: res.data,
          });
        })
        .catch((error) => {
          commit(REQUEST_PRODUCT_ERROR, { error });
        });
    },
    [CREATE_PRODUCT]({ commit }, payload) {
      debugger;
      return monoApi
        .post(`/products`, payload)
        .then((res) => {
          debugger;
          commit(REQUEST_CREATE_PRODUCT_SUCCESS, {
            payload: res.data.product,
          });
          router.push(`/products?status=success`);
        })
        .catch((error) => {
          debugger;
          commit(REQUEST_CREATE_PRODUCT_ERROR, { error });
        });
    },
    [DELETE_PRODUCT](context, id) {
      const { commit, state } = context;
      return monoApi
        .delete(`/products/${id}`)
        .then((res) => {
          commit(REQUEST_DELETE_PRODUCT_SUCCESS, {
            payload: state.items.filter((item) => {
              if (item._id !== res.data.product._id) return item;
            }),
          });
          return Promise.resolve(`Producto eliminado con éxito`);
        })
        .catch((error) => {
          commit(REQUEST_DELETE_PRODUCT_ERROR, { error });
          return Promise.reject(`Error: ${error.response.data.message}`);
        });
    },
    [UPDATE_PRODUCT]({ commit, state }, payload) {
      return monoApi
        .patch(`/products/${payload._id}`, payload)
        .then((res) => {
          debugger;
          const newItems = [];
          state.items.forEach((item) => {
            if (item._id === res.data.product._id)
              newItems.push(res.data.product);
            else newItems.push(item);
          });
          commit(REQUEST_UPDATE_PRODUCT_SUCCESS, {
            payload: newItems,
          });
          router.push(`/products?status=success`);
        })
        .catch((error) => {
          debugger;
          commit(REQUEST_UPDATE_PRODUCT_ERROR, { error });
        });
    },

    [GET_PRODUCTS_BY_OWNER_ID]({ commit }, id) {
      commit(REQUEST_PRODUCT_BY_OWNER);
      monoApi
        .get(`/products/owner/${id}`)
        .then((res) => {
          commit(REQUEST_PRODUCT_BY_OWNER_SUCCESS, {
            payload: res.data,
          });
        })
        .catch((error) => {
          commit(REQUEST_PRODUCT_BY_OWNER_ERROR, { error });
        });
    },
  },
  mutations: {
    [REQUEST_PRODUCT_BY_OWNER](state) {
      state.isFetching = true;
      state.error = null;
    },

    [REQUEST_PRODUCT_BY_OWNER_SUCCESS](state, action) {
      state.isFetching = false;
      state.item = [...action.payload];
      state.error = null;
    },

    [REQUEST_PRODUCT_SUCCESS](state, action) {
      state.isFetching = false;
      state.item = action.payload;
      state.error = null;
    },
    [REQUEST_CREATE_PRODUCT_SUCCESS](state, action) {
      state.isFetching = false;
      state.items = [...state.items, action.payload];
      state.error = null;
    },
    [REQUEST_UPDATE_PRODUCT_SUCCESS](state, action) {
      state.isFetching = false;
      state.items = [...action.payload];
      state.error = null;
    },
    [REQUEST_DELETE_PRODUCT_SUCCESS](state, action) {
      state.isFetching = false;
      state.items = [...action.payload];
      state.error = null;
    },

    [REQUEST_PRODUCT_BY_OWNER_ERROR](state, action) {
      state.isFetching = false;
      state.error = action.error;
    },

    [REQUEST_PRODUCT_ERROR](state, action) {
      state.isFetching = false;
      state.error = action.error;
    },
    [REQUEST_RESTAURANT_SUCCESS](state, action) {
      state.isFetching = false;
      state.error = action.error;
    },

    [REQUEST_PRODUCT_BY_OWNER_SUCCESS](state, action) {
      state.isFetching = false;
      state.items = [...action.payload];
      state.error = null;
    },
  },
};
